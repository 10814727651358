<template>
  <div class="verification sign-in d-flex flex-column align-center mx-4">
    <div class="text-h4 mt-16 mb-8">{{ $t('__verification_title') }}</div>
    <v-card class="ma-2"
      outlined
      max-width="500"
      width="100%">
      <v-card-text class="pa-6">
        <div class="mb-8">{{ $t('__verification_message') }}</div>
        <div class="my-8 d-flex align-center">
          <div>{{ $t('__email_address') }}</div>
          <v-card class="mx-4" outlined color="grey lighten-3"><v-card-text class="py-1 px-2">{{ userEMail }}</v-card-text></v-card>
          <v-icon v-if="userIsEMailVerified" dark color="green">mdi-check-circle</v-icon>
          <v-icon v-else dark color="red">mdi-close-circle</v-icon>
        </div>
        <v-btn class="my-8"
          block
          color="primary"
          :disabled="userIsEMailVerified || sendEMailVerificationWaitTime > 0"
          @click="sendEMailVerification">
          <template v-if="sendEMailVerificationWaitTime === 0">{{ $t('__send_email_verification') }}</template>
          <template v-else>{{ $t('__resend_email_verification_in_second').replace('%1', sendEMailVerificationWaitTime.toString()) }}</template>
        </v-btn>

        <v-divider/>

        <div class="my-8 d-flex align-center">
          <div>{{ $t('__phone_number') }}</div>
          <v-card class="mx-4" outlined color="grey lighten-3"><v-card-text class="py-1 px-2">{{ userPhoneNumber }}</v-card-text></v-card>
          <v-icon v-if="userIsPhoneNumberVerified" dark color="green">mdi-check-circle</v-icon>
          <v-icon v-else dark color="red">mdi-close-circle</v-icon>
        </div>
        <v-btn class="mt-8"
          block
          color="primary"
          :disabled="userIsPhoneNumberVerified || sendSMSVerificationWaitTime > 0"
          @click="sendSMSVerification">
          <template v-if="sendSMSVerificationWaitTime === 0">{{ $t('__send_sms_verification') }}</template>
          <template v-else>{{ $t('__resend_sms_verification_in_second').replace('%1', sendSMSVerificationWaitTime.toString()) }}</template>
        </v-btn>
        <div id="recaptcha-container" class="mt-8"/>
        <div class="my-8 d-flex align-center">
          <div>{{ $t('__phone_number_verification_code') }}</div>
          <v-text-field class="mx-4"
            outlined
            dense
            hide-details="auto"
            v-model="phoneNumberVerificationCode"
            type="number"/>
          <v-btn
            color="primary"
            :disabled="userIsPhoneNumberVerified || phoneNumberVerificationCode.length === 0"
            :loading="phoneNumberVerifying"
            @click="verifyPhoneNumber">
            {{ $t('__phone_number_verify') }}
          </v-btn>
        </div>
        <div class="caption mb-2 error--text">{{ phoneNumberVerificationError }}</div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { firebase } from '@/plugins/firebase'

export default {
  name: 'Verification',
  computed: {
    ...mapGetters({
      userFirebaseUser: "user/firebaseUser",
      userEMail: "user/email",
      userIsEMailVerified: "user/isEMailVerified",
      userPhoneNumber: "user/phoneNumber",
      userIsPhoneNumberVerified: "user/isPhoneNumberVerified"
    })
  },
  data() {
    return {
      sendEMailVerificationWaitTime: 0,
      sendSMSVerificationWaitTime: 0,
      phoneNumberRecaptchaVerifier: null,
      phoneNumberProvider: null,
      phoneNumberVerificationID: '',
      phoneNumberVerificationCode: '',
      phoneNumberVerifying: false,
      phoneNumberVerificationError: '',
      checkVerifiedTimeout: null
    }
  },
  methods: {
    sendEMailVerification() {
      this.userFirebaseUser.sendEmailVerification()
      this.setSendEMailVerificationWaitTime(60);
    },
    sendSMSVerification() {
      if (!this.phoneNumberRecaptchaVerifier) this.phoneNumberRecaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container')
      if (!this.phoneNumberProvider) this.phoneNumberProvider = new firebase.auth.PhoneAuthProvider()
      this.phoneNumberProvider.verifyPhoneNumber(this.userPhoneNumber, this.phoneNumberRecaptchaVerifier)
      .then(verificationID => {
        this.phoneNumberVerificationID = verificationID
      })
      .catch(err => {
        let errorMessage = this.parseError(err)
        const errCode = err.code
        if (errCode === 'auth/invalid-phone-number' ||
            errCode === 'auth/missing-phone-number' ||
            errCode === 'auth/unverified-email') {
          this.phoneNumberVerificationError = errorMessage
        } else {
          this.phoneNumberVerificationError = this.$t('__send_sms_verification_failed')
          console.error(errorMessage, err)
        }
      })
      this.setSendSMSVerificationWaitTime(60);
    },
    verifyPhoneNumber() {
      this.phoneNumberVerifying = true
      const authCredential = firebase.auth.PhoneAuthProvider.credential(this.phoneNumberVerificationID, this.phoneNumberVerificationCode)
      this.userFirebaseUser.updatePhoneNumber(authCredential)
      .then(() => {
        this.phoneNumberVerifying = false
      })
      .catch(err => {
        let errorMessage = this.parseError(err)
        const errCode = err.code
        if (errCode === 'auth/missing-verification-code' ||
            errCode === 'auth/invalid-verification-code' ||
            errCode === 'auth/credential-already-in-use') {
          this.phoneNumberVerificationError = errorMessage
        } else {
          this.phoneNumberVerificationError = this.$t('__verification_failed')
          console.error(errorMessage, err)
        }
        this.phoneNumberVerifying = false
      })
    },
    setSendEMailVerificationWaitTime(second) {
      this.sendEMailVerificationWaitTime = second
      this.setCountDownSendEMailVerificationWaitTime()
    },
    setSendSMSVerificationWaitTime(second) {
      this.sendSMSVerificationWaitTime = second
      this.setCountDownSendSMSVerificationWaitTime()
    },
    setCountDownSendEMailVerificationWaitTime() {
      if (this.sendEMailVerificationWaitTime > 0) {
        setTimeout(() => {
          this.sendEMailVerificationWaitTime -= 1
          this.setCountDownSendEMailVerificationWaitTime()
        }, 1000);
      }
    },
    setCountDownSendSMSVerificationWaitTime() {
      if (this.sendSMSVerificationWaitTime > 0) {
        setTimeout(() => {
          this.sendSMSVerificationWaitTime -= 1
          this.setCountDownSendSMSVerificationWaitTime()
        }, 1000);
      }
    },
    parseError(err) {
      const errCode = err.code
      const errMessage = err.message
      let errorMessage = ''
      if (errCode) {
        let tKey = `__firebase_error_${errCode}`
        if (errCode === 'auth/credential-already-in-use') {
          tKey += '_' + err.credential.providerId + '_' + err.credential.signInMethod
        }
        errorMessage = this.$t(tKey)
        if (errorMessage === tKey) {
          if (errMessage) {
            errorMessage = errMessage
          } else {
            errorMessage = errCode
          }
        }
      } else if (errMessage) {
        errorMessage = errMessage
      } else {
        errorMessage = err
      }
      return errorMessage
    },
    checkVerified() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('user/reloadFirebaseUser')
        .then(() => {
          if (this.userIsEMailVerified && this.userIsPhoneNumberVerified) {
            if (this.$route.query.redirect) {
              this.$router.replace(this.$route.query.redirect)
            } else {
              this.$router.replace({ name: 'Home' })
            }
            resolve()
          }
          reject()
        })
      })
    },
    setCheckVerifiedTimeout(time) {
      this.checkVerifiedTimeout = setTimeout(() => {
        this.checkVerified()
        .then(() => {})
        .catch(() => this.setCheckVerifiedTimeout(time))
      }, time);
    }
  },
  created() {
    this.checkVerified()
    .catch(() => {})
    this.setCheckVerifiedTimeout(2000)
    this.setSendEMailVerificationWaitTime(0)
    this.setSendSMSVerificationWaitTime(0)
  },
  beforeDestroy() {
    clearTimeout(this.checkVerifiedTimeout)
  }
}
</script>